import { Question } from './question';
import { Player } from './player';

export enum Phase {
  ANSWER = 'A',
  QUESTION = 'Q',
}

export interface Game {
  code: string;
  question: Question | null;
  player_set: Player[];
  task: null | string;
  phase: Phase;
}
