/* eslint-disable global-require */
const avatars = [
  require('../assets/avatars/1_1.png'),
  require('../assets/avatars/1_2.png'),
  require('../assets/avatars/1_3.png'),
  require('../assets/avatars/1_4.png'),
  require('../assets/avatars/1_5.png'),
  require('../assets/avatars/1_6.png'),
  require('../assets/avatars/1_8.png'),
  require('../assets/avatars/1_9.png'),
  require('../assets/avatars/2_1.png'),
  require('../assets/avatars/2_2.png'),
  require('../assets/avatars/2_3.png'),
  require('../assets/avatars/2_4.png'),
  require('../assets/avatars/2_5.png'),
  require('../assets/avatars/2_6.png'),
  require('../assets/avatars/2_7.png'),
  require('../assets/avatars/2_8.png'),
  require('../assets/avatars/2_9.png'),
  require('../assets/avatars/3_1.png'),
  require('../assets/avatars/3_2.png'),
  require('../assets/avatars/3_3.png'),
  require('../assets/avatars/3_4.png'),
  require('../assets/avatars/3_5.png'),
  require('../assets/avatars/3_6.png'),
  require('../assets/avatars/3_7.png'),
  require('../assets/avatars/3_8.png'),
  require('../assets/avatars/3_9.png'),
  require('../assets/avatars/4_1.png'),
  require('../assets/avatars/4_2.png'),
  require('../assets/avatars/4_3.png'),
  require('../assets/avatars/4_4.png'),
  require('../assets/avatars/4_5.png'),
  require('../assets/avatars/4_6.png'),
  require('../assets/avatars/4_7.png'),
  require('../assets/avatars/4_8.png'),
  require('../assets/avatars/4_9.png'),
  require('../assets/avatars/5_1.png'),
  require('../assets/avatars/5_2.png'),
  require('../assets/avatars/5_3.png'),
  require('../assets/avatars/5_4.png'),
  require('../assets/avatars/5_5.png'),
  require('../assets/avatars/5_6.png'),
  require('../assets/avatars/5_7.png'),
  require('../assets/avatars/5_8.png'),
  require('../assets/avatars/5_9.png'),
  require('../assets/avatars/6_1.png'),
  require('../assets/avatars/6_2.png'),
  require('../assets/avatars/6_3.png'),
  require('../assets/avatars/6_4.png'),
  require('../assets/avatars/6_5.png'),
  require('../assets/avatars/7_1.png'),
  require('../assets/avatars/7_2.png'),
  require('../assets/avatars/7_3.png'),
  require('../assets/avatars/7_4.png'),
  require('../assets/avatars/7_5.png'),
  require('../assets/avatars/8_1.png'),
  require('../assets/avatars/8_2.png'),
  require('../assets/avatars/8_3.png'),
  require('../assets/avatars/8_4.png'),
  require('../assets/avatars/8_5.png'),
  require('../assets/avatars/9_1.png'),
  require('../assets/avatars/9_2.png'),
  require('../assets/avatars/9_3.png'),
  require('../assets/avatars/9_4.png'),
  require('../assets/avatars/9_5.png'),
];
const unknown = require('../assets/avatars/unknown.png');
/* eslint-enable global-require */

function intToAvatar(int: number | undefined) {
  if (int === undefined) {
    return unknown;
  }
  return avatars[int % avatars.length];
}

function randomAvatarIndex() {
  return Math.floor(Math.random() * avatars.length);
}

export default { randomAvatarIndex, intToAvatar };
