import React, { Component } from 'react';
import { View } from 'react-native';
import { Game, Phase } from '../interfaces/game';
import { Player } from '../interfaces/player';
import main from '../styles/main';
import PlayerCard from './PlayerCard';

/* Return the most occuring values of a sortable array. */
const mode = (arr: Array<any>) => [...new Set(arr)]
  .map((value) => [value, arr.filter((v) => v === value).length])
  .sort((a, b) => a[1] - b[1])
  .reverse()
  .filter((value, i, a) => a.indexOf(value) === i)
  .filter((v, i, a) => v[1] === a[0][1])
  .map((v) => v[0]);

interface Props {
  game: Game,
  player: Player,
  socket: WebSocket,
}

// eslint-disable-next-line react/prefer-stateless-function
class VotingArea extends Component<Props> {
  render() {
    // Get the PKs of the players that are voted for the most
    // 1. get all the votes; 2. remove the null votes (incase host clicked next); 3. get the mode
    const mostOccuringPks = mode(this.props.game.player_set.map((p) => p.voted_for).filter((p) => p));
    return (
      <View style={[main.center, main.row]}>
        {this.props.game.player_set.map((player: Player) => (
          <PlayerCard
            player={player}
            game={this.props.game}
            ownPlayer={this.props.player}
            won={this.props.game.phase === Phase.ANSWER && mostOccuringPks.includes(player.pk)}
            socket={this.props.socket}
            key={`voting-area-of-${player.pk}`}
          />
        ))}
      </View>
    );
  }
}

export default VotingArea;
