import { View, Image } from 'react-native';
import React from 'react';
import avatars from '../scripts/avatars';
import { Game, Phase } from '../interfaces/game';
import { Player } from '../interfaces/player';

interface Props {
  game: Game,
  player: Player,
}

function findPlayer(game: Game, pk: number) {
  return game.player_set.find((player: Player) => player.pk === pk);
}

function VotedByList(props: Props) {
  // Show nothing if not in answer phase
  if (props.game.phase !== Phase.ANSWER) {
    return null;
  }

  // To overlap the icons, we give each image marginLeft: -10.
  // This however, means that we need to offset this left margin on the first image
  // hence, the marginLeft: 10.
  return (
    <View style={{ flexDirection: 'row', marginLeft: 10 }}>
      {props.player.voted_by.map((votedBy: number) => (
        <Image
          source={avatars.intToAvatar(findPlayer(props.game, votedBy)?.profile_picture)}
          key={`${props.player.pk}-voted-by-${votedBy}`}
          style={{
            width: 20, height: 20, borderRadius: 20, marginLeft: -10,
          }}
        />
      ))}
    </View>
  );
}

export default VotedByList;
