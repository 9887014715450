import React, { Component } from 'react';
import {
  View, Text, TextInput, TouchableOpacity, Image, StyleSheet,
} from 'react-native';
import cookie from 'react-cookies';
import { withRouter } from 'react-router-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faDice } from '@fortawesome/free-solid-svg-icons';
import inputStyles from '../styles/input';
import api from '../scripts/api';
import avatars from '../scripts/avatars';
import { Player } from '../interfaces/player';
import main from '../styles/main';

interface Props {
  history: any,
}

const styles = StyleSheet.create({
  dice: {
    marginBottom: 20,
    marginLeft: 75,
    marginTop: -50,
  },
});

class Join extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      name: '',
      profilePicture: avatars.randomAvatarIndex(),
    };
  }

  componentDidMount() {
    if (cookie.load('join')) {
      const code = cookie.load('join');
      cookie.remove('join');

      this.setState({ code });
    }
  }

  setRandomAvatarIndex = () => {
    this.setState({ profilePicture: avatars.randomAvatarIndex() });
  }

  handleCode = (code: string) => {
    this.setState({ code: code.toUpperCase() });
  }

  handleName = (name: string) => {
    this.setState({ name });
  }

  join = () => {
    api.post('players', {
      name: this.state.name,
      room_code: this.state.code,
      profile_picture: this.state.profilePicture,
    // eslint-disable-next-line react/prop-types
    }).then((game) => this.props.history.push({
      pathname: '/game',
      state: {
        game,
        player: game?.player_set.find((p: Player) => p.name === this.state.name),
      },
    })).catch(() => { });
  }

  render() {
    return (
      <View style={{
        alignItems: 'center',
      }}
      >
        <Image
          source={avatars.intToAvatar(this.state.profilePicture)}
          style={inputStyles.inputImage}
        />
        <TouchableOpacity
          style={[styles.dice, inputStyles.iconInput]}
          onPress={this.setRandomAvatarIndex}
        >
          <FontAwesomeIcon icon={faDice} color="#FFCA28" size={32} />
        </TouchableOpacity>
        <TextInput
          style={[inputStyles.primaryInput, main.mb1]}
          placeholder="Name"
          placeholderTextColor="#D1C4E9"
          autoCapitalize="words"
          onChangeText={this.handleName}
          onSubmitEditing={() => this.roomInput.focus()}
          autoFocus
        />
        <TextInput
          style={[inputStyles.primaryInput, main.mb1]}
          placeholder="Room code"
          placeholderTextColor="#D1C4E9"
          autoCapitalize="characters"
          onChangeText={this.handleCode}
          ref={(input) => { this.roomInput = input; }}
          onSubmitEditing={this.join}
          value={this.state.code}
        />
        <TouchableOpacity
          onPress={this.join}
          style={inputStyles.primaryBtn}
        >
          <Text style={inputStyles.primaryBtnText}>Join</Text>
        </TouchableOpacity>
      </View>
    );
  }
}
export default withRouter(Join);
