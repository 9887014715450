import React, { Component } from 'react';
import {
  View,
} from 'react-native';
import { withRouter } from 'react-router-native';
import cookie from 'react-cookies';
import env from '../scripts/env';
import { Player } from '../interfaces/player';
import { Game } from '../interfaces/game';
import Controls from '../components/Controls';
import VotingArea from '../components/VotingArea';
import Question from '../components/Question';
import Task from '../components/Task';
import api from '../scripts/api';
import main from '../styles/main';

interface GameState {
  game?: Game,
  player?: Player,
  socket?: WebSocket,
}

interface Props {
  history: any,
  location: any,
}

class QuestionComponent extends Component<Props> {
  // eslint-disable-next-line react/state-in-constructor
  state: GameState;

  constructor(props: any) {
    super(props);

    // eslint-disable-next-line react/prop-types
    this.state = this.props.location.state;
    const self = this;
    this.state.socket = new WebSocket(
      `ws${env.SECURITY}://${env.API_URL}/ws/game/${this.state.game?.code}/`,
    );

    cookie.save('room_code', true, { maxAge: 60 * 60 * 5 });

    this.state.socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if ('game' in data) {
        self.state.game = data.game;
        self.state.player = self.state.game?.player_set.find((p) => p.pk === self.state.player?.pk);
        // Player is not found, could be because the player is kicked
        if (!self.state.player) {
          // eslint-disable-next-line no-undef
          toast.show('You were kicked from the game.', { type: 'danger' });
          api.quitSession().finally(() => this.props.history.push({ pathname: '/' }));
        }
        self.forceUpdate();
      }
      if ('message' in data) {
        if (data.status >= 400) {
          // eslint-disable-next-line no-undef
          toast.show(data.message, { type: 'danger' });
        } else {
          // eslint-disable-next-line no-undef
          toast.show(data.message, { type: 'success' });
        }
      }
    };

    this.state.socket.onclose = () => {
      // eslint-disable-next-line no-undef
      toast.show('You lost connection to the server. Reload the page to join again');
    };
  }

  render() {
    // Only load when all values are loaded as well
    if (!this.state.game || !this.state.player || !this.state.socket) {
      return <View />;
    }
    return (
      <View style={[main.center, main.m1]}>
        <Question
          question={this.state.game.question}
        />
        <Task
          socket={this.state.socket}
          player={this.state.player}
          game={this.state.game}
        />
        <VotingArea
          socket={this.state.socket}
          player={this.state.player}
          game={this.state.game}
        />
        <Controls
          socket={this.state.socket}
          player={this.state.player}
          game={this.state.game}
        />
      </View>
    );
  }
}

export default withRouter(QuestionComponent);
