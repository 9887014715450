import cookie from 'react-cookies';
import env from './env';

interface IDictionary<TValue> {
  [id: string]: TValue;
}

function pathToUrl(path: string, params: IDictionary<any> | null = null) {
  let improvedUrl = `http${env.SECURITY}://${env.API_URL}`;
  if (!path.startsWith('/')) {
    improvedUrl = `${improvedUrl}/${path}`;
  } else {
    improvedUrl = `${improvedUrl}${path}`;
  }
  if (improvedUrl.slice(-1) !== '/' && !improvedUrl.includes('?')) { improvedUrl += '/'; }
  if (params) {
    improvedUrl += '?';
    Object.keys(params).forEach((key: string) => { improvedUrl += `${key}=${encodeURIComponent(params[key])}&`; });
    improvedUrl = improvedUrl.slice(0, -1);
  }

  return improvedUrl;
}

function api(url: string, method: string, body: Object | null = null) {
  const data = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    method,
  };
  if (body) {
    data.body = JSON.stringify(body);
  }
  return fetch(url, data)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if ([400, 401, 403, 404, 500].includes(response.status)) {
        response.json()
          .then((respData) => {
            const message = respData?.message;
            if (!message) {
              throw new Error('No message supplied');
            }
            // If there is a message supplied, send that
            // eslint-disable-next-line no-undef
            toast.show(message, { type: 'danger' });
          })
          .catch(() => {
            // If there is no message supplied, default back to the statustext if it has one
            // Else simply say "Something went wrong"
            if (response.statusText) {
              // eslint-disable-next-line no-undef
              toast.show(response.statusText, { type: 'danger' });
            } else {
              // eslint-disable-next-line no-undef
              toast.show('Something went wrong.', { type: 'danger' });
            }
          });
      }

      throw response;
    });
}

function deleteCall(path: string, params: IDictionary<any> | null = null) {
  return api(pathToUrl(path, params), 'DELETE');
}

export default {
  get(path: string, params: IDictionary<any> | null = null) {
    return api(pathToUrl(path, params), 'GET');
  },
  post(path: string, data: IDictionary<any> | null = null) {
    return api(pathToUrl(path), 'POST', data);
  },
  patch(path: string, data: IDictionary<any> | null = null) {
    return api(pathToUrl(path), 'PATCH', data);
  },
  delete: deleteCall,
  api,
  quitSession() {
    return deleteCall('players/0').catch().finally(() => {
      cookie.remove('room_code');
      cookie.remove('sessionid');
    });
  },
  hasSession(): boolean {
    return !!cookie.load('room_code');
  },
};
