import React from 'react';
import {
  Image, Text, TouchableOpacity, Platform, ViewStyle, TextStyle,
} from 'react-native';
import * as Linking from 'expo-linking';

function openURL(url: string) {
  if (Platform.OS === 'web') {
    window.open(url, '_blank');
  } else {
    Linking.openURL(url);
  }
}

function KoFi() {
  const url = 'https://ko-fi.com/larspolo';
  const supported = Linking.canOpenURL(url);
  if (!supported) {
    return null;
  }
  const mainStyle: ViewStyle = {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  };
  const textStyle: TextStyle = {
    color: 'white',
    fontSize: 18,
    marginHorizontal: 5,
  };
  return (
    <TouchableOpacity style={mainStyle} onPress={() => openURL(url)}>
      <Image
        style={{
          height: 25, width: 42, marginRight: 5,
        }}
        // eslint-disable-next-line global-require
        source={require('../assets/kofi.webp')}
      />
      <Text
        style={textStyle}
      >
        Share a drink!
      </Text>
    </TouchableOpacity>
  );
}

export default KoFi;
