import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  iconInput: {
    backgroundColor: '#7E57C2',
    borderColor: '#9575CD',
    borderRadius: 100,
    borderWidth: 3,
    color: '#FFCA28',
    padding: 3,
  },
  inputImage: {
    borderColor: '#9575CD',
    borderRadius: 100,
    borderWidth: 3,
    height: 150,
    marginBottom: 20,
    width: 150,
  },
  invalidBtn: {
    alignItems: 'center',
    backgroundColor: '#FFCA28',
    borderRadius: 5,
    color: 'black',
    display: 'flex',
    elevation: 5,
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 18,
    justifyContent: 'center',
    margin: 5,
    minWidth: 120,
    padding: 12,
    textTransform: 'uppercase',
  },
  primaryBtn: {
    alignItems: 'center',
    backgroundColor: '#FFCA28',
    borderRadius: 5,
    color: 'black',
    display: 'flex',
    elevation: 5,
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontSize: 18,
    justifyContent: 'center',
    margin: 5,
    minWidth: 120,
    padding: 12,
    shadowColor: '#000',
    shadowOffset: {
      width: 3,
      height: 3,
    },
    shadowOpacity: 1,
    shadowRadius: 0,
    textTransform: 'uppercase',
  },
  primaryBtnText: {
    color: 'black',
    fontSize: 18,
    marginHorizontal: 5,
    textTransform: 'uppercase',
  },
  primaryInput: {
    borderColor: '#9575CD',
    borderRadius: 10,
    borderWidth: 3,
    color: 'white',
    fontSize: 18,
    minWidth: 200,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});
