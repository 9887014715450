import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  prompt: {
    borderColor: '#9575CD',
    borderRadius: 10,
    borderWidth: 3,
    color: 'white',
    fontSize: 32,
    maxWidth: 1000,
    minWidth: 200,
    paddingHorizontal: 20,
    paddingVertical: 10,
    textAlign: 'center',
  },
});
