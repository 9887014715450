import React, { Component } from 'react';
import {
  View, Text, TextInput, TouchableOpacity, Image, StyleSheet,
} from 'react-native';
import { CheckBox } from 'react-native-elements';
import { withRouter } from 'react-router-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faDice } from '@fortawesome/free-solid-svg-icons';
import inputStyles from '../styles/input';
import api from '../scripts/api';
import { Player } from '../interfaces/player';
import avatars from '../scripts/avatars';
import main from '../styles/main';

const styles = StyleSheet.create({
  dice: {
    marginBottom: 20,
    marginLeft: 75,
    marginTop: -50,
  },
});
interface Props {
  history: any,
}

class Create extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      profilePicture: avatars.randomAvatarIndex(),
      showTask: true,
    };
  }

  handleName = (name: string) => {
    this.setState({ name });
  }

  setRandomAvatarIndex = () => {
    this.setState({ profilePicture: avatars.randomAvatarIndex() });
  }

  create = () => {
    api.post('games', {
      name: this.state.name,
      profile_picture: this.state.profilePicture,
      show_task: this.state.showTask,
    // eslint-disable-next-line react/prop-types
    }).then((game) => this.props.history.push({
      pathname: '/game',
      state: {
        game,
        player: game?.player_set.find((p: Player) => p.name === this.state.name),
      },
    })).catch(() => { });
  }

  render() {
    return (
      <View style={{
        alignItems: 'center',
      }}
      >
        <Image
          source={avatars.intToAvatar(this.state.profilePicture)}
          style={inputStyles.inputImage}
        />
        <TouchableOpacity
          style={[styles.dice, inputStyles.iconInput]}
          onPress={this.setRandomAvatarIndex}
        >
          <FontAwesomeIcon icon={faDice} color="#FFCA28" size={32} />
        </TouchableOpacity>
        <TextInput
          nativeID="create-game-name-input"
          style={[inputStyles.primaryInput, main.mb1]}
          placeholder="Name"
          placeholderTextColor="#D1C4E9"
          autoCapitalize="words"
          onChangeText={this.handleName}
          onSubmitEditing={this.create}
          autoFocus
        />
        <CheckBox
          nativeID="create-game-show-tasks-checkbox"
          title="include drinking tasks"
          checked={this.state.showTask}
          onPress={() => this.setState((prevState) => ({ showTask: !prevState.showTask }))}
          containerStyle={[inputStyles.primaryInput, {
            backgroundColor: '#7e57c2',
            marginBottom: 10,
          }]}
          textStyle={{ color: 'white' }}
          checkedColor="#ffca28"
        />
        <TouchableOpacity
          nativeID="create-game-submit"
          onPress={this.create}
          style={inputStyles.primaryBtn}
        >
          <Text style={inputStyles.primaryBtnText}>Create</Text>
        </TouchableOpacity>
      </View>
    );
  }
}
export default withRouter(Create);
