import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import {
  StyleSheet,
  View,
} from 'react-native';
import React from 'react';

const styles = StyleSheet.create({
  badge: {
    alignItems: 'center',
    color: '#FFA000',
    justifyContent: 'center',
    position: 'absolute',
    right: -120,
    top: -40,
  },
});

interface Props {
    won: boolean
}

function Crown(props: Props) {
  if (!props.won) {
    return null;
  }
  return (
    <View>
      <View style={styles.badge}>
        <FontAwesomeIcon icon={faMedal} color="#FFA000" size={24} />
      </View>
    </View>
  );
}

export default Crown;
