import { StyleSheet } from 'react-native';

const size = 10;

export default StyleSheet.create({
  center: {
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  m1: {
    margin: size * 1,
  },
  m2: {
    margin: size * 2,
  },
  m3: {
    margin: size * 3,
  },
  mb1: {
    marginBottom: size * 1,
  },
  mt1: {
    marginTop: size * 1,
  },
  row: {
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
});
