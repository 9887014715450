import React from 'react';
import {
  NativeRouter, Route, nativeHistory,
} from 'react-router-native';
import { View, StyleSheet } from 'react-native';
import Home from './scenes/Home';
import Join from './scenes/Join';
import Create from './scenes/Create';
import Game from './scenes/Game';

import HomeButton from './components/HomeButton';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#7E57C2',
    color: 'white',
    flex: 1,
    fontSize: 50,
    justifyContent: 'center',
  },
});

function Routes() {
  return (
    <NativeRouter history={nativeHistory}>
      <View style={styles.container}>
        <HomeButton />

        <Route exact path="/" component={Home} />
        <Route path="/join" component={Join} />
        <Route path="/create" component={Create} />
        <Route path="/game" component={Game} />
      </View>
    </NativeRouter>
  );
}
export default Routes;
