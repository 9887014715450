import React from 'react';
import { Text } from 'react-native';
import { Question } from '../interfaces/question';
import prompt from '../styles/prompt';

interface Props {
    question: Question | null
}

function QuestionComponent(props: Props) {
  return (
    <Text
      style={prompt.prompt}
      nativeID="question-text"
    >
      {
        props.question?.question
          ? props.question.question
          : 'We are all out of questions, add your own to continue playing!'
      }
    </Text>
  );
}

export default QuestionComponent;
