import { StyleSheet, View, Text } from 'react-native';
import React, { Component } from 'react';

import { Link } from 'react-router-native';
import { StatusBar } from 'expo-status-bar';
import { Image } from 'react-native-elements/dist/image/Image';
import { Game } from '../interfaces/game';
import { Player } from '../interfaces/player';
import api from '../scripts/api';
import input from '../styles/input';
import BackgroundQuestion from '../components/BackgroundQuestion';

const styles = StyleSheet.create({
  titleImage: {
    height: 200,
    marginBottom: 15,
    marginTop: -10,
    width: 200,
  },
});

interface GetGameResponse {
  game: Game,
  player: Player
}

interface Props {
  history: any,
}

class Home extends Component<Props> {
  constructor(props: any) {
    super(props);

    this.promptReconnect();
  }

  rejoin() {
    api.get('games/ongoing_session').then((response: GetGameResponse) => this.props.history.push({
      pathname: '/game',
      state: {
        game: response.game,
        player: response.player,
      },
    })).catch();
  }

  promptReconnect() {
    if (api.hasSession()) {
      // TODO Check if window.confirm works on Android.
      if (window.confirm('Do you wish to jump back into the game?')) {
        this.rejoin();
      } else {
        api.quitSession();
      }
    }
  }

  // checkAndRedirectJoin() {
  //   // Check if url is join url.
  //   const path = this.props.location.pathname;
  //   if (path.includes('join')) {
  //     const code = path.split('/')[2];

  //     cookie.save('join', code, { path: '/' });

  //     this.props.location.pathname = '';
  //   }

  //   if (cookie.load('join')) {
  //     this.props.history.push({
  //       pathname: '/join',
  //     });
  //   }
  // }

  render() {
    return (
      <View>
        <Link
          nativeID="join-game-button"
          style={input.primaryBtn}
          to="/join"
        >
          <Text style={input.primaryBtnText}>Join</Text>
        </Link>
        <Image
          // eslint-disable-next-line global-require
          source={require('../assets/icon-no-background.png')}
          style={styles.titleImage}
        />
        <Link
          nativeID="home-create-game-button"
          style={input.primaryBtn}
          to="/create"
        >
          <Text style={input.primaryBtnText}>Create</Text>
        </Link>
        <StatusBar />
        <BackgroundQuestion
          questions={[
            'Who is most often late?',
            'Who is the laziest?',
            'Who has the dirtiest room?',
            'Who loses their keys most often?',
          ]}
        />
        <BackgroundQuestion
          questions={[
            'Who would be able to date a celebrity?',
            'Who is most likely to ask why rain is wet?',
            'Who takes the longest showers?',
            'Who games the most often?',
          ]}
        />
        <BackgroundQuestion
          questions={[
            'Who is most likely to become rich?',
            'Who do you trust the most?',
            'Who is the strongest?',
            'Who is the bbq master?',
          ]}
        />
      </View>
    );
  }
}

export default Home;
