import React from 'react';
import Toast from 'react-native-fast-toast';
import Routes from './Routes';

export default function App() {
  return (
    <>
      <Routes />
      <Toast ref={(ref) => { global.toast = ref; }} />
    </>
  );
}
