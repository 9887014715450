import { StyleSheet, TextStyle } from 'react-native';

const genericCard: TextStyle = {
  alignItems: 'center',
  backgroundColor: '#EEE',
  borderColor: '#EEE',
  borderRadius: 10,
  borderStyle: 'solid',
  borderWidth: 3,
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  margin: 10,
  minWidth: 200,
  padding: 10,
  textAlignVertical: 'center',
};

export default StyleSheet.create({
  electedCard: {
    ...genericCard,
    borderColor: '#FFC107',
    shadowColor: '#FFC107',
    shadowRadius: 10,
  },
  genericCard,
  playerCard: genericCard,
  votedCard: {
    ...genericCard,
    borderColor: '#4CAF50',
  },
});
