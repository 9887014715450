import {
  StyleSheet,
  View,
  Text,
  Dimensions,
} from 'react-native';
import FadeInOut from 'react-native-fade-in-out';
import React, { useEffect, useState } from 'react';

// Get a random number between min and max (excluding max) in steps of `stepSize`
// e.g.: gridRandom(0, 10, 3) could give the values, 0, 3, 6, and 9
function gridRandom(min: number, max: number, stepSize: number) {
  return Math.floor(((Math.random() * (max - min)) + min) / stepSize) * stepSize;
}

function getRandomQuestion(questions: Array<string>) {
  return questions[Math.floor(Math.random() * questions.length)];
}

function checkLocationIsInvalid(location: { top: number, left: number }) {
  // These locations are already covered by the icon or buttons
  // So the background question should not be here
  const invalidLocation = [
    [0, -50], // Top button
    [0, 0], // Top button
    [0, 50], // In between
    [0, 100], // Logo
    [0, 150], // Logo
    [0, 200], // In between
    [0, 250], // Bottom button
    [0, 300], // Bottom button
  ];
  return invalidLocation.some(
    (item) => item[0] === location.left && item[1] === location.top,
  );
}

function getRandomLocation() {
  const { width, height } = Dimensions.get('window');
  // The location is top left location of the text
  // and is relative from a bit above the center of the screen
  // hance the magic numbers
  let location = {
    left: gridRandom(-(width - 700) / 2, (width - 500) / 2, 250),
    top: gridRandom(-(height - 500) / 2, (height + 100) / 2, 50),
  };
  let i = 0;
  // After 10 tries, and still nothing is found, quit the loop
  while (checkLocationIsInvalid(location) || i > 10) {
    location = {
      left: gridRandom(-(width - 700) / 2, (width - 500) / 2, 250),
      top: gridRandom(-(height - 500) / 2, (height + 100) / 2, 50),
    };
    i += 1;
  }
  return location;
}

function getRandomDisappearTime() {
  // Between 2 and 8 seconds it stays on screen
  return Math.random() * 8000 + 2000;
}

function getRandomAppearTime() {
  // Between 1.5 and 6 seconds it stays off screen
  return Math.random() * 4500 + 1500;
}

interface Props {
  questions: Array<string>
}

function BackgroundQuestion(props: Props) {
  const [visible, setVisible] = useState(false);
  const [randomLocation, setRandomLocation] = useState(getRandomLocation());
  const [randomQuestion, setRandomQuestion] = useState(getRandomQuestion(props.questions));

  const toggleVisible = () => {
    if (!visible) {
      setRandomQuestion(getRandomQuestion(props.questions));
      setRandomLocation(getRandomLocation());
    }
    setVisible(!visible);
  };

  useEffect(() => {
    const timer = setInterval(() => { toggleVisible(); }, visible ? getRandomDisappearTime() : getRandomAppearTime());
    return () => clearInterval(timer);
  });

  const styles = StyleSheet.create({
    main: {
      alignItems: 'center',
      color: 'white',
      flex: 1,
      justifyContent: 'center',
      padding: 10,
      position: 'absolute',
      textAlign: 'center',
      width: 250,
      zIndex: -1,
      ...randomLocation,
    },
    text: {
      color: 'white',
      fontSize: 18,
    },
  });

  return (
    <View
      style={styles.main}
    >
      <FadeInOut
        duration={1000}
        visible={visible}
      >
        <Text
          style={styles.text}
        >
          {randomQuestion}
        </Text>
      </FadeInOut>
    </View>
  );
}

export default BackgroundQuestion;
