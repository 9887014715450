import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-native';
import React from 'react';
import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  home: {
    alignItems: 'center',
    backgroundColor: '#FFCA28',
    borderRadius: 100,
    color: 'black',
    flex: 1,
    justifyContent: 'center',
    left: 5,
    padding: 10,
    position: 'absolute',
    top: 5,
    zIndex: 1,
  },
});

function HomeButton() {
  const location = useLocation();

  if (location.pathname === '/join' || location.pathname === '/create') {
    return (
      <Link
        style={styles.home}
        to="/"
      >
        <FontAwesomeIcon icon={faHome} />
      </Link>
    );
  }

  return null;
}

export default HomeButton;
