import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { Component } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Player } from '../interfaces/player';
import input from '../styles/input';
import main from '../styles/main';
import prompt from '../styles/prompt';
import { Game, Phase } from '../interfaces/game';

interface Props {
    game: Game,
    player: Player,
    socket: WebSocket,
}

class Task extends Component<Props> {
  get nextQuestion() {
    if (this.props.player.is_host) {
      return (
        <TouchableOpacity
          style={input.primaryBtn}
          onPress={() => this.next()}
        >
          <Text
            nativeID="task-next"
            style={input.primaryBtnText}
          >
            Next
          </Text>
          <FontAwesomeIcon icon={faArrowRight} />
        </TouchableOpacity>
      );
    }
    return null;
  }

  next = () => {
    this.props.socket?.send(JSON.stringify({
      next: true,
    }));
  }

  render() {
    // Do not display when not task phase
    if (this.props.game.phase !== Phase.ANSWER) {
      return null;
    }
    // Only display the next button if no task is set
    if (this.props.game.task === null) {
      return this.nextQuestion;
    }

    return (
      <View style={main.center}>
        <Text
          nativeID="task-text"
          style={[prompt.prompt, main.m1]}
        >
          For the elected:
          {' '}
          {this.props.game.task}
        </Text>
        {this.nextQuestion}
      </View>
    );
  }
}

export default Task;
